import React, { useState, useEffect } from 'react'

const AllergenButton = ({ type, color, allergens, toggleAllergens, code }) => {

    const [isActive, toggleActive] = useState(false);

    useEffect(() => {
        if (allergens.includes(code)) {
            toggleActive(true);
        } else {

            toggleActive(false);
        }
    }, [allergens]);


    const codeIcon = {
        'V': 'fa-leaf',
        'M': 'fa-tint',
        'L': 'fa-tint',
        'G': 'fa-bread-slice'
    }
    const updateState = () => {
        toggleActive(!isActive);
        toggleAllergens(code);
    }

    return (
        <button onClick={() => updateState()} className={"allergen-button " + code + (isActive ? ' active' : '')}><i className={"fas " + codeIcon[code] + (isActive ? ' active' : '')}></i>{type}</button>
    )
};

export default AllergenButton;