import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';

import Main from './components/Main';
function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyCvJQg_GdGloPBvy2Wy8ixJgU0DXaMqdYw",
    authDomain: "lounaskardda.firebaseapp.com",
    databaseURL: "https://lounaskardda.firebaseio.com",
    projectId: "lounaskardda",
    storageBucket: "lounaskardda.appspot.com",
    messagingSenderId: "850145273822",
    appId: "1:850145273822:web:313133112f1519ab111f4f",
    measurementId: "G-E7WLXS3RJJ"
  };

  firebase.initializeApp(firebaseConfig);
  // firebase.functions().useFunctionsEmulator("http://localhost:5000");
  const analytics = firebase.analytics()

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Main analytics={analytics} />
      {/* <PrivacyPolicy /> */}
    </MuiPickersUtilsProvider>

  );
}

export default App;
