import React, { useState, useEffect } from 'react';
import AllergenButton from './AllergenButton';

const MobileNav = (props) => {
    const [NavOpen, SetNavOpen] = useState(false);
    const [allergens, setAllergens] = useState(props.allergens);
    const [locating, setLocating] = useState(props.locating);
    const [viewModeState, setViewModeState] = useState(props.listView);
    const allergens_buttons = [
        {
            color: "green",
            text: "Vegaaninen",
            code: "V",
        },
        {
            color: "blue",
            text: "Laktoositon",
            code: "L"
        },
        {
            color: "blue",
            text: "Maidoton",
            code: "M"
        },
        {
            color: "orange",
            text: "Gluteeniton",
            code: "G"
        },
    ];

    useEffect(() => {
        setLocating(props.locating);
    }, [props.locating])

    useEffect(() => {
        setAllergens(props.allergens)
    }, [props.allergens])


    const toggleViewMode = (mode) => {
        setViewModeState(mode);
        props.toggleViewMode(mode);
    }

    return (
        <div className="header-nav-container">
            {/* <input type="text" name="search-input" className="search-input" placeholder="Etsi sijaintia" /> */}
            {/* <AddressSearch /> */}
            <button onClick={() => props.getGeoLocation()} className={"button-locate-nav" + (props.locating ? ' active' : '')}>
                <svg xmlns='http://www.w3.org/2000/svg' className='icon-locate' viewBox='0 0 512 512'><title>Locate</title><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='48' d='M256 96V56M256 456v-40' /><path d='M256 112a144 144 0 10144 144 144 144 0 00-144-144z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' /><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='48' d='M416 256h40M56 256h40' /></svg>
                <span>{props.locating ? 'Paikantaa' : 'Paikanna'}</span>
            </button>

            <button onClick={() => toggleViewMode(!viewModeState)} className={'display-mode-button fas fa-2x ' + (viewModeState ? 'fa-map' : 'fa-list-alt')}><span></span></button>
            <button onClick={() => SetNavOpen(!NavOpen)} className={'nav-button fas fa-2x fa-cog' + (NavOpen ? ' nav-button-open' : '')}><span></span></button>
            <div className={"nav-overlay" + (NavOpen ? ' nav-overlay-visible' : '')}>
                <ul>
                    {/* <li>Valitse näkymä
                        <div className="mode-buttons">
                            <button onClick={() => toggleViewMode(true)} className={'mode-button' + (viewModeState ? ' active' : '')}>Lista</button>
                            <button onClick={() => toggleViewMode(false)} className={'mode-button' + (!viewModeState ? ' active' : '')}>Kartta</button>
                        </div>
                    </li> */}
                    <li><h4 className="mobile-menu-header">Valitse allergeenit</h4>
                        <div className="allergen-buttons">
                            {allergens_buttons.map((a, i) => (
                                <AllergenButton
                                    key={i}
                                    type={a.text}
                                    color={a.color}
                                    allergens={allergens}
                                    toggleAllergens={props.toggleAllergens}
                                    code={a.code}
                                />
                            ))}
                        </div>

                    </li>
                    <li><h4 className="mobile-menu-header">Paikanna minut</h4>
                    <div className="mode-buttons">
                            <button onClick={props.getGeoLocation} disabled={locating} className={'locate-button ' + (locating ? 'active' : '')}>{locating ? 'Paikantaa' : 'Paikanna'}</button>

                        </div>
                    </li>

                </ul>
            </div>
        </div >
    );

}

export default MobileNav;