import React from "react";
// import SubNav from "./SubNav";
// import SearchNav from './SearchNav';
import MobileNav from './MobileNav';
// import DesktopNav from './DesktopNav';
const Header = (props) => {

  return (
    <>
      <header id="main-header">

        <div className="header-container">
          <div className="header-title-container">
            <div className="header-logo"></div>
            <h2 className="section-title">Lounaskartta.fi</h2>
          </div>

          <MobileNav
            locating={props.locating}
            getGeoLocation={props.getGeoLocation}
            allergens={props.allergens}
            toggleAllergens={props.setAllergens}
            viewMode={props.viewMode}
            toggleViewMode={props.toggleViewMode} />
        </div>
      </header>
    </>
  );
};

export default Header;
