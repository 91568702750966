import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { GoogleMap, InfoWindow, useLoadScript, Autocomplete } from "@react-google-maps/api";
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import MapContainerButton from './MapContainerButton';
import MapMarker from './MapMarker';
moment().locale('fi');
const libraries = ['places'];

const MapContainer = (props) => {
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const ALLERGENS = ['L', 'V', 'VL', 'G', 'K', 'PÄ', 'M'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAzyDqaL7-s9Cnkz6wCx6KP7xM1cAkhDl4",
    libraries: libraries
  });

  // Map reference, set when google map is initialized. Used for picking up bounds when map is scrolled/zoomed.
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 64.991622,
    lng: 25.4686425
  });
  const [highlighted, setHighlighted] = useState(null);
  const [infoView, setInfoView] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateJSON, setDateJSON] = useState(null);
  const [firstFetch, setFirstFetch] = useState(true);
  const [zoom, setZoom] = useState(11);
  const [showAllMarkers, setShowAllMarkers] = useState(true);


  const [searchBox, setSearchBox] = useState(null);

  let timeout_time = 250;
  let timeout;

  useEffect(() => {
    let clicked = props.visibleLunches.filter(vl => vl.id === props.clickedListElement).shift();
    if (clicked) {
      setInfoView(clicked);
    }
  }, [props.clickedListElement])

  useEffect(() => {
    setHighlighted(props.highlighted);
  }, [props.highlighted])

  useEffect(() => {
    let z = JSON.parse(sessionStorage.getItem('current_zoom'));
    if (z && z.hasOwnProperty('ttl') && moment().isBefore(moment(z.ttl))) {
      setZoom(z.zoom);
    }
  }, [])

  useEffect(() => {
    setDateJSON(moment(selectedDate).format('DDMMYYYY'));
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(props.selectedDate);

  }, [props.selectedDate]);

  useEffect(() => {
    if (props.position !== null) {
      // console.log("Ylemmältä tasolta tuli sijaintitieto");
      setCurrentPosition(props.position);
      setBounds();
    }
  }, [props.position])

  /* Passes current map bounds to Main */
  const setBounds = () => {
    if (map) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => sendBounds(map), timeout_time);
    }
  }

  const sendBounds = (map) => {
    let ttl = moment().add(5, 'm');
    sessionStorage.setItem('current_zoom', JSON.stringify({ zoom: map.zoom, ttl: ttl }));

    const center = map.getCenter();
    sessionStorage.setItem('current_position', JSON.stringify({
      lat: center.lat(),
      lng: center.lng(),
      ttl: ttl
    }));

    let data = {
      lat: {
        ne: map.getBounds().getNorthEast().lat(),
        sw: map.getBounds().getSouthWest().lat()
      },
      lng: {
        ne: map.getBounds().getNorthEast().lng(),
        sw: map.getBounds().getSouthWest().lng()
      },
      zoom: map.zoom
    };

    props.setBounds(data);
  }



  const getFreeGeoIP = () => {
    axios.get('https://freegeoip.app/json/').then((res) => {
      if (res.hasOwnProperty('data') && res.data.hasOwnProperty('latitude')) {
        const currentPosition = {
          lat: res.data.latitude,
          lng: res.data.longitude
        };
        setCurrentPosition(currentPosition);
      }
    });
  }

  // Fetch IP-based location only if we have no recent location on storage
  useEffect(() => {
    const pos = JSON.parse(sessionStorage.getItem('current_position'));
    if (pos && moment().isAfter(moment(pos.ttl))) {
      getFreeGeoIP();
    }
  }, []);

  const handleOnLoad = map => {
    const google = window.google;
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(<MapContainerButton showAllMarkers={showAllMarkers} setShowAllMarkers={setShowAllMarkers} />, controlButtonDiv)
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlButtonDiv);
  };


  const onPlacesChanged = (e) => {
    if (searchBox !== null) {
      const place = searchBox.getPlace();

      if (!place.hasOwnProperty('geometry')) {
        return;
      }

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      setCurrentPosition(location);
      setBounds();
      setZoom(13);
    }
  }

  // return (
  const renderMap = () => {
    return (
      <GoogleMap
        options={{
          gestureHandling: 'greedy',
          panControl: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: [
            {
              "featureType": "poi",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ],
          minZoom: 9,
          maxZoom: 16
        }}


        clickableIcons={false}
        mapContainerStyle={mapStyles}
        zoom={zoom}

        draggable={true}
        center={currentPosition}
        onMouseDown={() => {
          clearTimeout(timeout);
          timeout = null;
        }}


        onLoad={(map) => { setMap(map); handleOnLoad(map); }}
        onClick={() => {
          setInfoView(null);
          props.setClickedListElement(null);
        }}

        onDragEnd={() => {
          setBounds();
          setInfoView(null);
        }}
        onTilesLoaded={() => {
          if (firstFetch) {
            setBounds();
            setFirstFetch(false);
          }
        }}

        onZoomChanged={setBounds}
      >
        <Autocomplete
          onLoad={(ref) => setSearchBox(ref)}
          onPlaceChanged={onPlacesChanged}
          restrictions={{
            country: 'fi'
          }}
          bounds={{
            east: 31.640625,
            west: 20.710430,
            north: 70.10861,
            south: 59.662191
          }}
        >
          <input
            type="text"
            placeholder="Hae kaupunkia tai osoitetta"
            className="map-search-input"
          />
        </Autocomplete>

        {props.visibleLunches.filter(vl => showAllMarkers || vl.valid).map((d, i) => (
          <MapMarker
            data={d}
            key={i}
            dateJSON={dateJSON}
            setInfoView={setInfoView}
            analytics={props.analytics}
            hoveredKey={!infoView ? highlighted : infoView && infoView.id !== d.id ? highlighted : null}
          />
        ))}

        {infoView &&
          <InfoWindow
            options={{
              pixelOffset: { width: 0, height: -34 }
            }}
            position={{ lng: infoView.lng, lat: infoView.lat }}
          // onCloseClick={() => {
          //   // setHoveredMarker(null);
          //   props.setClickedListElement(null);
          //   setInfoView(null);
          // }}
          >
            <div className="marker-card">
              {/* <button className="btn marker-card-close-button">Sulje</button> */}
              <div className="marker-card-meta">
                <div className="marker-card-meta-img" />
                <ul className="meta">
                  <li className="meta-name">{infoView.meta.name}</li>
                  <li className="meta-address">{infoView.meta.address}</li>
                  <li className="meta-address">{infoView.meta.postalno} {infoView.meta.city}</li>
                </ul>
              </div>

              <ul className="lunch">
                <li className="lunch-date">{moment(selectedDate).locale('fi').format('dddd, DD.MM.')}</li>
                {!infoView.list.hasOwnProperty(dateJSON) && (
                  <li className="lunch-name">Ravintolalla ei ole lounaslistaa valitulle päivälle.</li>
                )}
                {infoView.list.hasOwnProperty(dateJSON) && (
                  <>
                    {infoView.list[dateJSON].hasOwnProperty('served') && (
                      <li className="lunch-schedule">Lounas {infoView.list[dateJSON].served.from} - {infoView.list[dateJSON].served.to}</li>

                    )}
                    <li className="bottom-line centered"></li>
                    {infoView.list[dateJSON].lunch.map((l, i) => (
                      <li className="lunch-name" key={i}><div className="name-container">{l.name}</div><div className="allergen-container">{l.allergens.filter(a => ALLERGENS.includes(a)).map((a, i) => (<div key={i} className={"allergen " + a}></div>))}</div></li>
                    ))}
                  </>
                )}
              </ul>
            </div>

          </InfoWindow>

        }

      </GoogleMap>
    )
  }
  return isLoaded ? renderMap() : 'Ladataan karttaa...';
};

export default MapContainer;
