import React, { useState, useEffect } from "react";
import "../scss/Main.scss";
import Header from "./Header";
import Footer from "./Footer";
import Layout from "./Layout";
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/functions';
import moment from 'moment';

const Main = ({ analytics }) => {
	const [NavOpen, toggleNavOpen] = useState(false);
	const [allergens, setAllergens] = useState([]);
	const [listView, setListActive] = useState(false);
	const [bounds, setBounds] = useState(null);
	const [position, setPosition] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [mapLoading, setMapLoading] = useState(false);
	const [locating, setLocating] = useState(false);
	const [searchTerm, setSearchTerm] = useState(null);

	// Preprocessed lunch list. Used after bounds or allergens change. Once filtered, used in UI.
	const [visibleLunches, setVisibleLunches] = useState([]);

	/** NEW WAY OF FETCHING HERE: FIRST FETCH ALL MARKERS, AND UPLOAD DATA WHENEVER MAP MOVES OVER MARKER. IF MARKER HAS DATA, DON'T UPDATE */
	const [coreData, setCoreData] = useState([]);

	const toggleViewMode = (mode) => {
		window.scrollTo(0, 0);
		setListActive(mode);
	}

	// Initialize: get location and fetch restaurant location list before anything else.
	useEffect(() => {
		setMapLoading(true);
		setInitialLocation();
		getRestaurantLocations();
		setMapLoading(false);
	}, []);

	useEffect(() => {
		if (bounds !== null && coreData.length > 0) {

			checkDataFromBounds();
		}
	}, [bounds])

	useEffect(() => {
		if (bounds !== null && coreData.length > 0) {

			checkDataFromBounds();
		}
	}, [coreData])

	const setInitialLocation = () => {
		const lastPos = JSON.parse(sessionStorage.getItem("current_position"));
		if (lastPos && moment().isBefore(moment(lastPos.ttl))) {
			setPosition(lastPos);
		} else {
			getGeoLocation();
		}
	}

	/** Fetches all metadata of restaurants available. No lunch-list data is fetched yet. */
	const getRestaurantLocations = (async () => {
		const itemStr = sessionStorage.getItem("lunch_data");
		if (itemStr) {
			setCoreData(JSON.parse(itemStr));
			return true;
		}

		const getAllMarkers = firebase.app().functions('europe-west1').httpsCallable('getAllMarkers');
		let response = await getAllMarkers();
		setCoreData(response.data);
		sessionStorage.setItem('lunch_data', JSON.stringify(response.data));
		setMapLoading(false);
	});

	const checkValidity = (place) => {
		const checker = (arr, target) => target.every(v => arr.includes(v));
		const d = moment(selectedDate).format('DDMMYYYY');
		if (!place.list.hasOwnProperty(d) || place.list[d].lunch.length === 0) {
			return false;
		}

		let search = '';
		if (searchTerm !== null && searchTerm.length > 0) {
			search = searchTerm.replace(/[^0-9a-zåäö\-.,\s]/gi, '').toLowerCase();
		}

		let searchTermFound = true;
		if (search.length > 0) {
			if (!place.meta.name.toLowerCase().includes(search)) {
				searchTermFound = false;
			}
		}

		if (allergens.length === 0 && searchTermFound) {
			return true;
		}

		for (const lunch of place.list[d].lunch) {
			if (lunch.hasOwnProperty('allergens') && !checker(lunch.allergens, allergens)) {
				continue;
			}
			if (!searchTermFound && !lunch.name.toLowerCase().includes(search)) {
				continue;
			}
			return true;
		}
		return false;
	}

	const checkDataFromBounds = (async () => {
		setMapLoading(true);
		let filtered = coreData.filter(c => bounds.lat.sw <= c.lat && c.lat <= bounds.lat.ne && bounds.lng.sw <= c.lng && c.lng <= bounds.lng.ne);
		let fullData = [];
		let needsData = [];
		// Näkyvillä olevat paikat tässä, katsotaan onko haettu jo niiden data
		for (const f of filtered) {
			if (!f.hasOwnProperty("list") && (!f.hasOwnProperty('updated') || f.updated === false)) {
				needsData.push(f);
			} else {
				f.valid = checkValidity(f);
				fullData.push(f);
			}
		}

		if (visibleLunches.length > fullData.length) {
			window.scrollTo(0, 0)
		}
		setVisibleLunches(fullData);

		if (needsData.length > 0) {
			const fetchMissing = firebase.app().functions('europe-west1').httpsCallable('fetchMissing');
			let keys = needsData.reduce((acc, value) => { acc.push(value.id); return acc }, []);
			let response = await fetchMissing(keys);
			let cd = [...coreData];

			for (let i = 0; i < cd.length; i++) {
				cd[i].updated = keys.includes(cd.id) ? true : false;
				let id = cd[i].id;

				for (const r of response.data) {
					if (r.id === id) {
						cd[i].meta = r.meta;
						cd[i].list = r.list;
						break;
					}
				}
			}
			setCoreData(cd);
			sessionStorage.setItem('lunch_data', JSON.stringify(cd));
		}
		setMapLoading(false);
	});

	useEffect(() => {
		let vL = [...visibleLunches];
		for (const i in vL) {
			vL[i].valid = checkValidity(vL[i]);
		}
		setVisibleLunches(vL);
	}, [searchTerm])


	useEffect(() => {
		let vL = [...visibleLunches];
		for (const i in vL) {
			vL[i].valid = checkValidity(vL[i]);
		}
		setVisibleLunches(vL);
	}, [allergens]);

	const getGeoLocation = (async () => {
		const geoLocate = () => {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					setLocating(false);
					position = {
						lat: pos.coords.latitude,
						lng: pos.coords.longitude
					};
					setPosition(position);
					sessionStorage.setItem('current_position', JSON.stringify({
						...position,
						ttl: moment().add(5, 'm')
					}));
				},
				(err) => { setLocating(false); console.log(err); },
				{ enableHighAccuracy: false, timeout: 5000, maximumAge: 20000 });
		}

		let position = null;
		if (navigator.geolocation) {
			setLocating(true);
			if (!navigator.permissions) {
				geoLocate();
			} else {
				navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
					if (result.state === 'denied') {
						setLocating(false);
						//TODO: show notification
					}
					geoLocate();
				});
			}
		}
	});

	const toggleAllergens = (a) => {
		let all = [...allergens];
		if (all.includes(a)) {
			all = all.filter((x => x !== a))
		} else {
			all.push(a);
		}
		setAllergens(all);
	}

	return (
		<>
			<Header
				locating={locating}
				getGeoLocation={getGeoLocation}
				allergens={allergens}
				setAllergens={toggleAllergens}
				viewMode={listView}
				toggleViewMode={toggleViewMode} />

			<Layout
				mapLoading={mapLoading}
				analytics={analytics}
				selectedDate={selectedDate}
				position={position}
				visibleLunches={visibleLunches}
				setBounds={setBounds}
				allergens={allergens}
				listView={listView}
				setAllergens={toggleAllergens}
				setSearchTerm={setSearchTerm}
			/>
			<Footer toggleNavOpen={toggleNavOpen} NavOpen={NavOpen} />
			{/* <CookiePermissions setConsentCookies /> */}
		</>
	);
};

export default Main;
