import React from "react";

const Footer = () => {
  return (

    <footer id="main-footer">
      {/* <div className="container"> */}
      <div className="footer-container">
        <div>
          <h3>Lounaskartta.fi</h3>
          <p>&copy; 2021 Lounaskartta.fi</p>
          <a href="https://www.facebook.com/lounaskarttafi">
            <svg xmlns='http://www.w3.org/2000/svg' className='icon-fb' viewBox='0 0 512 512'><title>Logo Facebook</title><path d='M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z' fillRule='evenodd' /></svg>
          </a>
        </div>
        <div>
          <h3>Miten palvelu toimii?</h3>
          <p>Lounaskartta.fi näyttää kartan rajaamalta alueelta lounasravintolat, jotka tarjoilevat asetettuihin ruokavalioihin soveltuvia lounaita kyseiselle päivälle.</p>
          <br />
          <p>Tavoitteenamme on selkeyttää lounasravintoloiden sijainnin havaitsemista asettamalla ne kartalle. Kartalla näkyviä ravintoloita voi suodattaa hakutoimintojen avulla.</p>
        </div>
        <div>
          <h3>Ravintolasi listalle?</h3>
          <p>Haluatko ravintolasi näkyviin listalle? Ota yhteyttä sähköpostitse tuki (at) lounaskartta.fi!</p>
          <h3>Auta jatkokehityksessä!</h3>
          <p>Palvelua kehitetään aktiivisesti. Uusia toimintoja voi ehdottaa lähettämällä sähköpostia tuki (at) lounaskartta.fi</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
