import React, { useState, useEffect } from 'react'
import { Marker } from "@react-google-maps/api";
// import icon from './icon_active_40.png';
// import icon_inactive from './icon_inactive_40.png';

const MapMarker = ({ data, setInfoView, analytics, hoveredKey }) => {
    const [animate, setAnimate] = useState(null);

    useEffect(() => {
        // console.log(hoveredKey);
    }, [hoveredKey]);

    useEffect(() => {
        // console.log("MapMarker, tarkistan onko " + data.id + " sama kuin " + hoveredKey);
        if (data.id === hoveredKey) {
            setAnimate(1);
        } else if (hoveredKey === null) {
            setAnimate(null);
        }
    }, [hoveredKey])

    return (
        <Marker
            animation={animate}

            // icon={data.hasOwnProperty('list') && data.list.hasOwnProperty(dateJSON) && validateAllergens(data.list[dateJSON]) ? icon : icon_inactive}
            // icon={data.valid ? icon : icon_inactive}
            icon={data.valid ? '/marker_red.png' : '/marker_gray.png'}
            position={{ lat: data.lat, lng: data.lng }}
            onClick={() => {
                // console.log(data.id);
                if (data.hasOwnProperty('meta')) {
                    setInfoView(data);
                    analytics.logEvent('open_marker', { content_id: data.id });
                }
            }}
        />
    )
}

export default MapMarker;