import React, { useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import Tooltip from '@material-ui/core/Tooltip';

moment().locale('fi');

const FoodCard = (props) => {
    const [lunchdata, setLunchData] = useState(null);
    const [targetDate, setTargetDate] = useState(); // TODO: Use value passed from Layout
    const [cardContent, setCardContent] = useState("");
    const [hoveredCard, setHoveredCard] = useState(null);

    const allergens = {
        'G': 'Gluteeniton',
        'L': 'Laktoositon',
        'M': 'Maidoton',
        'K': 'Kasvisruoka',
        'V': 'Vegaani',
        'VL': 'Vähälaktoosinen',
        'PÄ': 'Sisältää pähkinää',
    }

    useEffect(() => {
        // console.log("Hoveroin nyt korttia:", hoveredCard);
        props.targetMarker(hoveredCard);
    }, [hoveredCard]);

    useEffect(() => {
        setLunchData(props.data);
    }, [props.data]);

    useEffect(() => {
        setTargetDate(props.date)
    }, [props.date]);


    useEffect(() => {
        if (targetDate !== null && lunchdata !== "") {
            draw();
        }
    }, [targetDate, lunchdata]);

    const draw = () => {
        // console.log("DRAW cutsuttu");
        if (!targetDate || !lunchdata) {
            return;
        }
        // const prettyDate = moment(targetDate).locale('fi').format('dddd, DD.MM.');
        const jsonDate = moment(targetDate).format('DDMMYYYY');

        let lunches = (<div>Ravintolalla ei ole lounastietoja valitulle päivälle</div>);

        if (lunchdata.hasOwnProperty('list') && lunchdata.list.hasOwnProperty(jsonDate)) {
            lunches = (<ul className="lunches">
                {/* <li className="day mb-1">{prettyDate}</li> */}
                {/* {lunchdata.list[jsonDate].hasOwnProperty('served') && (
                    <li className="time">Lounas {lunchdata.list[jsonDate].served.from} - {lunchdata.list[jsonDate].served.to}</li>
                )} */}
                {/* <li className="bottom-line left"></li> */}

                {lunchdata.list[jsonDate].lunch.map((x, i) => (
                    <li className={"lunch"} key={i}>
                        <span className="lunch-name">{x.name}</span>
                        <span className="lunch-allergens">

                            {x.allergens !== null && x.allergens.filter(a => allergens.hasOwnProperty(a)).map((a, i) => (
                                <Tooltip key={i} arrow placement="top" title={[allergens[a]]}>
                                    <span className={"allergen " + a}></span>

                                    {/* <span className="allergen-stylefree">{a}</span> */}
                                </Tooltip>
                            ))}
                        </span>
                    </li>
                ))}
            </ul>)
        }


        let card = (
            <div ref={props.forwardRef} onClick={() => props.setClickedListElement(lunchdata.id)} onMouseEnter={() => setHoveredCard(props.marker)} onMouseLeave={() => setHoveredCard(null)} className="layout-card">

                <div className="foodcard-restaurant">
                    <h4 className="foodcard-restaurant-name">{lunchdata.hasOwnProperty('meta') && (
                        lunchdata.meta.name)}
                    </h4>
                    <p className="foodcard-restaurant-address">{lunchdata.meta.address}<br />{lunchdata.meta.pcode} {lunchdata.meta.city}</p>
                    {lunchdata.list[jsonDate].hasOwnProperty('served') && lunchdata.list[jsonDate].served !== null && (
                        <p className="foodcard-restaurant-time">Lounas {lunchdata.list[jsonDate].served.from} - {lunchdata.list[jsonDate].served.to}</p>
                    )}
                </div>

                <div className="layout-card-lunches">
                    {lunches}
                </div>
            </div>

        );

        setCardContent(card);
    }

    return (
        cardContent
    )
}

export default FoodCard;