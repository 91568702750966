import React, { useState } from 'react'

const MapContainerButton = (props) => {
    const [showAll, setShowAll] = useState(props.showAllMarkers);

    const toggleState = () => {
        setShowAll(!showAll);
        props.setShowAllMarkers(!showAll);
    }

    return (
        <button name="marker-state-button" className={"map-marker-mode-button" + (showAll ? '' : ' active')} onClick={toggleState} />
    );
}

export default MapContainerButton;