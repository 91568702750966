import React, { useRef, useState, useEffect } from "react";
import MapContainer from "./MapContainer";
import FoodCard from "./FoodCard";
import AllergenButton from "./AllergenButton";

const Layout = (props) => {
  const [highlight, setHighlight] = useState(null);
  const cardContainer = useRef(null);

  const maxPerPage = 20;
  const [selectedDate, setSelectedDate] = useState(null);
  const [showMoreCounter, setShowMoreCounter] = useState(1);
  const [visibleLunches, setVisibleLunches] = React.useState([]);
  const [clickedListElement, setClickedListElement] = useState(null);
  const [allergens, setAllergens] = useState(props.allergens);
  const allergens_buttons = [
    {
      color: "green",
      text: "Vegaaninen",
      code: "V",
    },
    {
      color: "blue",
      text: "Laktoositon",
      code: "L"
    },
    {
      color: "blue",
      text: "Maidoton",
      code: "M"
    },
    {
      color: "orange",
      text: "Gluteeniton",
      code: "G"
    }
  ];

  const toggleHighlight = (val) => {
    setHighlight(val);
  }

  useEffect(() => {
    setAllergens(props.allergens);
    window.scrollTo(0, 0);
  }, [props.allergens]);

  useEffect(() => {
    let valid = props.visibleLunches.filter(vl => vl.valid);
    setShowMoreCounter(1);
    if (valid.length < visibleLunches.length) {
      window.scrollTo(0, 0);
    }

    setVisibleLunches(valid);
  }, [props.visibleLunches]);



  // Triggers when user changes desired lunch search date.
  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  return (
    <div className="layout-container">
      <div className={"layout-dynamic-list" + (props.listView ? "" : " hidden")}>
        <div className="layout-subnav-container">
          <div className="filters">
            {allergens_buttons.map((a, i) => (
              <AllergenButton
                key={i}
                type={a.text}
                color={a.color}
                allergens={allergens}
                toggleAllergens={props.setAllergens}
                code={a.code}
              />
            ))}
          </div>
          <div className="search-filter">
            <input type="text" onChange={(e) => props.setSearchTerm(e.target.value)} className="search-filter-input" placeholder='Hae ruokaa tai ravintolaa, esim "pasta"' />
          </div>
        </div>
        <p className="mt-1 p-1 text-center">
          Lounaskartta.fi ei ole vastuussa allergeenien
          paikkaansapitävyydestä. Tarkista allergeenit aina
          ravintoloitsijalta.
            </p>
        <div ref={cardContainer} className="card-container">

          {visibleLunches.slice(0, showMoreCounter * maxPerPage).map(d => (
            <FoodCard
              key={(d.hasOwnProperty('meta') ? d.meta.name : '')}
              date={selectedDate}
              data={d}
              marker={d.id}
              targetMarker={toggleHighlight}
              setClickedListElement={setClickedListElement}
            />
          ))}

        </div>
        {visibleLunches.length >= showMoreCounter * maxPerPage && (
          <button onClick={() => setShowMoreCounter(showMoreCounter + 1)} className="btn bg-light
         my-1">Näytä lisää</button>
        )}


      </div>
      <div
        className={"layout-map-container overlay " + (props.mapLoading ? 'overlay-active' : '') + (props.listView ? " hidden" : "")}
      >
        <MapContainer
          setClickedListElement={setClickedListElement}
          clickedListElement={clickedListElement}
          allergens={props.allergens}
          position={props.position}
          visibleLunches={props.visibleLunches}
          setBounds={props.setBounds}
          highlighted={highlight}
          selectedDate={selectedDate}
          analytics={props.analytics}
        />
      </div>
    </div>
  );
};

export default Layout;
